import firebase from "firebase/app";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyCS-DZ-98scnFMqfdYixBEUJ6hGrYVXTTU",
  authDomain: "findmyfavourites-1545509021050.firebaseapp.com",
  projectId: "findmyfavourites-1545509021050",
};


export default firebase.initializeApp(config);
