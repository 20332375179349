import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import GoogleMapReact from 'google-map-react';
import 'antd/dist/antd.css';
import { Popover, Button, Menu, Icon , Modal, List, Select, Divider, Input} from 'antd';
import fire from './fire.js'

let db = fire.firestore()

class PopoverInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render () {
    return (
      <div>
        <p><b>Ratings: </b>{this.props.data.rating}</p>
        <p><b>Open Now: </b>{this.props.data.opening_hours && this.props.data.opening_hours.open_now ? 'Yes' : 'No'}</p>
      </div>
    )
  }
}

const AnyReactComponent = ({ details }) => (
  <Popover content={<PopoverInfo data={details}/> } title={
      <a href={`https://maps.google.com/maps/place/?q=place_id:${details.place_id}`}>
    {details.name}</a>} trigger="click">
    <div style={{zIndex: 5000, backgroundColor: 'white', color: '#484848', borderRadius: '50%', height: 60, width: 60}}>
      {
        details.logo ?
        <img
          style={{height: '100%', width: 'auto', borderRadius: '50%', border: '0.5px solid black'}}
          src={details.logo}/>
        :
        <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center',
          borderRadius: '50%',
          border: '1px solid black'}}>
          {details.text}
        </div>
      }

    </div>
  </Popover>
)

const placeDetails = {
  'Costa' : {
    logo: 'https://seeklogo.com/images/C/Costa_Coffee-logo-DC0FF384B3-seeklogo.com.png'
  },
  'Zizzi': {
    logo: 'https://www.zizzi.co.uk/assets/img/zizzi-roundel.png'
  },
  'Old Fashioned Sweet Shop': {
    logo: 'https://mrsimmsoldesweetshoppe.co.uk/wp-content/uploads/placeholder-2.png'
  },
  'Nandos': {
    logo: 'https://pbs.twimg.com/profile_images/691977669920038912/748UCJdK_400x400.jpg'
  },
  'Waterstones' : {
    logo: 'https://www.victoriacentrellandudno.co.uk/wp-content/uploads/2015/10/Waterstones-logo.jpg'
  },
  'Wahaca' : {
    logo: 'https://pbs.twimg.com/profile_images/691977669920038912/748UCJdK_400x400.jpg'
  }
}

const Option = Select.Option;

const defaultProps = {
  selectPerson: true,
  center: {
    lat: 51.8959,
    lng: 0.8919
  },
  zoom: 13,
  peopleSelected : ['Mum']
};

const people = [
  {name: 'Dad', image: 'https://i.imgur.com/iKCSM4r.jpg'},
  {name: 'Becca', image: 'https://i.imgur.com/SeU2Twt.jpg'},
  {name: 'Tom', image: 'https://i.imgur.com/emLsyIW.png'},
  {name: 'G&G', image: 'https://i.imgur.com/mHj1TCn.jpg'}
]

const styles = {
  personSelect: {
    borderRadius: 4,
    backgroundColor: 'white',
    color: 'black', width: 140,
    height: '20vh',
    boxSizing: 'border-box',
    margin: 20,
    position: 'relative'
  }
}

class SelectPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handlePersonSelect = (person) => {
    this.props.selectPerson(person.name)
  }

  render() {
    return (
      <div style={{height: '100%', width: '100%', padding: 5, boxSizing: 'border-box',
      }}>
      <h2 style={{fontWeight: 200, color: 'white', paddingLeft: 20, textAlign: 'left'}}>Who are you with?</h2>
        <div  onClick={() => this.handlePersonSelect({name: 'Mum'})}
          style={{ marginLeft: 20, marginRight: 20}}>
          <Divider />
          <Button type='primary'>
            Just you
          </Button>
          <Divider />
        </div>

        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {people.map((person) => (
            <div style={styles.personSelect}
              onClick={() => this.handlePersonSelect(person)}
              >
              <img src={person.image}
                style={{height: '100%', width: '100%', objectFit: 'cover',  borderRadius: 4}}/>
              <div style={{fontWeight: 200, fontSize: '26px', position: 'absolute', bottom: 5,
                width: '100%'}}>
                <Button type='primary'>
                  {person.name}
                </Button>

              </div>
            </div>
          ))}
        </div>

      </div>
    )
  }
}

class App extends Component {


  constructor(props) {
    super(props);
    this.state = defaultProps
    console.log(this.state)
  }


  onPlacesChanged = () => {
    console.log(this.searchBox.getPlaces())
  }

  handlePersonClick = (person) => {
    console.log('person selected')
    var selectedPeople = this.state.peopleSelected
    if (!selectedPeople.includes(person)) {
      selectedPeople.push(person)
    }
    this.setState({peopleSelected: selectedPeople, selectPerson: false})
    this.collectQueries(selectedPeople)
  }

  collectQueries = (selectedPeople) => {
    var queryTexts = []
    console.log(selectedPeople)
    var promises = []
    selectedPeople.forEach((person) => {
      promises.push(db.collection("Places")
      .where("People", "array-contains", person)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          if (!queryTexts.includes(doc.data().Query)) {
            queryTexts.push(doc.data().Query)
          }
        })
      }))
    })

    Promise.all(promises).then(() => {
      this.setState({queryTexts: queryTexts})
      console.log(queryTexts)
      queryTexts.forEach((query) => {
        if (!this.searchBox) {
          window.setTimeout(() => this.searchForText(query), 4000)
        } else {
          this.searchForText(query)
        }
      })
    })
  }

  handleChange = (value) => {
    var places =  []
    console.log(`selected ${value}`);
    this.setState({dropdownPerson: value, inputValue: null})
    db.collection("Places").where("People", "array-contains", value)
    .get().then((placesSnapshot) => {
      placesSnapshot.forEach((place) => {
        places.push(place.data())
      })
      this.setState({personPlaces: places})
    })
  }

  handleInputKeyPress = (e) => {
    console.log('button pressed')
    if (e.key === 'Enter') {
      console.log('add a place')
      db.collection("Places").add({
        Query: this.state.inputValue,
        People : [this.state.dropdownPerson]
      })
    }
  }

  componentDidMount(props) {
    window.navigator.permissions && window.navigator.permissions.query({name:'geolocation'})
      .then(function(permissionStatus) {
        console.log('geolocation permission state is ', permissionStatus.state);

        permissionStatus.onchange = function() {
          console.log('geolocation permission state has changed to ', this.state);
        };
      });

    navigator.geolocation && navigator.geolocation.getCurrentPosition(position => {
      console.log('Geolocation permissions granted');
      this.setState({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        myLocation: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    });
    this.handleChange('Mum')
  }

  searchForText = (text) => {
    this.searchBox.textSearch({query: text, radius: '20', location: this.state.center},
    (results, error) => {
      results.forEach((one) => {
        one.text = text
        one.logo = placeDetails[text] ? placeDetails[text].logo : null
      })
      var markers = this.state.markers ? this.state.markers : []
      markers = markers.concat(results)
      this.setState({markers: markers})

    })
  }

  handleLocationUpdate = () => {
    window.navigator.permissions.query({name:'geolocation'})
      .then(function(permissionStatus) {
        console.log('geolocation permission state is ', permissionStatus.state);

        permissionStatus.onchange = function() {
          console.log('geolocation permission state has changed to ', this.state);
        };
      });

    navigator.geolocation.getCurrentPosition(position => {
      console.log('Geolocation permissions granted');
      this.setState({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        myLocation: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    });
  }

  handleOk = (e) => {
    console.log(e);
    if (this.state.inputValue) {
      db.collection("Places").add({
        Query: this.state.inputValue,
        People : [this.state.dropdownPerson]
      })
    }
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false, inputValue: null
    });
}

  handleVisibleChange = (popvisible) => {
    this.setState({ popvisible: !this.state.popvisible });
  }

  handleDeleteListItem = (item) => {

  }

  render() {
    console.log(this.state)
    return (
      <div className="App">
        <header className="App-header">
          {
            this.state.selectPerson ?
            <SelectPerson
              selectPerson={this.handlePersonClick}
              /> :
              <div style={{ height: '100vh', width: '100%' }}>
                <Modal
                  title="Add/Remove places"
                  visible={this.state.visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >

                <Select defaultValue="Mum" style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value={'Mum'}>Mum</Option>
                  {
                    people.map((person) => (
                      <Option value={person.name}>{person.name}</Option>
                    ))
                  }
                </Select>
                <div style={{height: 10}}/>
                  <List
                    dataSource={this.state.personPlaces}
                    itemLayout='horizontal'
                    renderItem={item => (
                      <List.Item
                        actions={[<Icon type="delete"
                          onClick={() => this.handleDeleteListItem(item)}
                          style={{ fontSize: '16px', color: '#08c' }}
                          />]}
                        >
                        <div style={{paddingLeft: 11}}>
                          {item.Query}
                        </div>

                      </List.Item>
                    )}
                    >
                  </List>
                  <Input placeholder="Add new place"
                    onChange={(e) => this.setState({inputValue:e.target.value })}
                    value={this.state.inputValue}
                    onKeyPress={this.handleInputKeyPress}
                    />

                </Modal>
                <Popover
                  placement="topLeft"
                  visible={this.state.popvisible}
                  onVisibleChange={this.handleVisibleChange}
                  title='Settings' trigger="click"
                  content={
                    <div>
                      <Menu>

                      <Menu.Item
                        onClick={() => this.setState({selectPerson: true, popvisible: false,
                           markers: [], peopleSelected: ['Mum']})}
                        key="setting:1">Change People</Menu.Item>
                      <Menu.Item
                        onClick={() => this.setState({selectPerson: false, visible: true, popvisible: false})}
                        key="setting:2">Add/Remove Places</Menu.Item>

                    </Menu>
                    </div>
                  }>
                <div style={{backgroundColor: 'white', position: 'absolute',
                  color: 'black',
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  top: 10, left: 10, boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
                  borderRadius: 2, height: 40, width: 40, zIndex: 80}}
                  >
                  <Icon type="setting" style={{zIndex: 90, fontSize: '20px',
                    position: 'inherit', color: '#484848'}}/>
                </div>
              </Popover>
              <GoogleMapReact
                onDrag={this.handleLocationUpdate}
                onGoogleApiLoaded={({map, maps}) => {
                  this.googleMap = map
                  this.searchBox = new window.google.maps.places.PlacesService(this.googleMap);
                }}
                bootstrapURLKeys={{
                  key: 'AIzaSyC6DRR9A3N7w_LVL5xMm07h7z5UwMWDXv8',
                  language: 'en-GB',
                  region: 'GB',
                }}
                center={this.state.center}
                defaultZoom={this.state.zoom}
              >
                {this.state.markers ? this.state.markers.map((marker) => (
                  <AnyReactComponent
                    lat={marker.geometry.location.lat()}
                    lng={marker.geometry.location.lng()}
                    details={marker}

                  />
                ))
                :
                null
              }
              {
                this.state.myLocation ?
                <div
                  lat={this.state.myLocation.lat}
                  lng={this.state.myLocation.lng}
                  style={{borderRadius: '50%',
                    height: 20, width: 20,
                    backgroundColor: '#2196f3', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'}}
                  />
                :
                null
              }

              </GoogleMapReact>
            </div>
          }

        </header>
      </div>
    );
  }
}

export default App;
